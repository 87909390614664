import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import { ToastContainer } from "react-toastify";
import { PrivateRoutes } from "./Routes/PrivateRoutes";
import PageLoader from "./Components/Loaders/PageLoader";
import ProtectRoutes from "./Routes/ProtectedRoutes";
import ContactusButton from "./Pages/Contact/ContactusButton";

const Home = lazy(() => import("./Pages/Home/Home"));
const Navbar = lazy(() => import("./Components/Navbar/Navbar"));
const Footer = lazy(() => import("./Components/Footer/Footer"));
const PropertyDetails = lazy(() =>
  import("./Pages/PropertyDetails/PropertyDetails")
);
const AllProperty = lazy(() => import("./Pages/Properties/AllProperty"));
const Details = lazy(() =>
  import("./Pages/MyAccount/DashboardBody/PropertyDetails/Details")
);
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const NewLaunch = lazy(() => import("./Pages/NewLaunch/NewLaunch"));
const Wishlist = lazy(() => import("./Pages/Wishlist/Wishlist"));
const AboutUs = lazy(() => import("./Pages/AboutUs/AboutUs"));
const MyAccount = lazy(() => import("./Pages/MyAccount/MyAccount"));
const Compare = lazy(() => import("./Pages/Compare/Compare"));
const Login = lazy(() => import("./Pages/Login/Login"));
const ForgotPassword = lazy(() =>
  import("./Components/ForgotPassword/ForgotPassword")
);
const Blogs = lazy(() => import("./Pages/Blog/Blogs"));
const BlogDetails = lazy(() => import("./Pages/Blog/BlogDetails/BlogDetails"));
const Privacy = lazy(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const TermsCondition = lazy(() =>
  import("./Pages/TermsConditions/TermsConditions")
);

const App = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [mainLoader, setMainLoader] = useState(true);

  // useState(() => {
  //   setTimeout(() => setMainLoader(false), 1000);
  // }, []);

  useEffect(() => {
    const timer = setTimeout(() => setMainLoader(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (mainLoader) return <PageLoader />;

  return (
    <div className="bg-white">
      <Suspense fallback={<PageLoader />}>
        <Router>
          <ScrollToTop />
          <Navbar />
          <ContactusButton />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/propertydetails/:id" element={<PropertyDetails />} />
            <Route path="/properties" element={<AllProperty />} />
            <Route path="/newlaunch" element={<NewLaunch />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<AboutUs />} />
            <Route element={<ProtectRoutes user={!user} redirect="/" />}>
              <Route path="/login" element={<Login />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
            </Route>
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogdetails/:id" element={<BlogDetails />} />
            <Route path="/privacypolicy" element={<Privacy />} />
            <Route path="/termsconditions" element={<TermsCondition />} />
            <Route element={<PrivateRoutes user={user} />}>
              <Route path="/myaccount" element={<MyAccount />} />
              <Route path="/compare" element={<Compare />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/details/:id" element={<Details />} />
            </Route>
          </Routes>
          <Footer />
        </Router>
        <ToastContainer
          position="top-right"
          autoClose={1200}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          limit={1}
        />
      </Suspense>
    </div>
  );
};

export default App;
